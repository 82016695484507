<template>
    <div class="info-container">
        <div class="info-container-data mb-0_5 p-2">
            <h4 class="text-primary">Datos</h4>
            <div class="info-content info-container-grid mt-1">
                <div class="content mb-0_5">
                    <h6>Nombre del cliente</h6>
                    <h6 class="mt-0_5 text-primary">{{paymentsHistory.data.data.clientName ?? '--'}}</h6>

                </div>
                <div class="content mb-0_5">
                    <h6>RUC</h6>
                    <h6 class="mt-0_5 text-primary">{{paymentsHistory.data.data.ruc ?? '--'}}</h6>

                </div>
                <div class="content mb-0_5">
                    <h6>Código del cliente</h6>
                    <h6 class="mt-0_5 text-primary">{{paymentsHistory.data.data.clientCode ?? '--'}}</h6>

                </div>
                <div class="content mb-0_5">
                    <h6>Zona</h6>
                    <h6 class="mt-0_5 text-primary">{{paymentsHistory.data.data.zone ?? '--'}}</h6>

                </div>
                <div class="content mb-0_5">
                    <h6>Vendedor</h6>
                    <h6 class="mt-0_5 text-primary">{{paymentsHistory.data.data.seller ?? '--'}}</h6>

                </div>
            </div>
        </div>
        <div class="info-container-details mb-0_5 p-2">
            <h4 class="text-primary">Detalles</h4>
            <div class="info-content info-container-grid mt-1">
                <div class="content mb-0_5">
                    <h6>Línea de crédito</h6>
                    <h6 class="mt-0_5 text-primary">{{paymentsHistory.data.details.credit_line + ' PEN' ?? '--'}}</h6>
                </div>
                <div class="content mb-0_5">
                    <h6>Deuda Total</h6>
                    <h6 class="mt-0_5 text-primary">{{paymentsHistory.data.details.total_debt + ' PEN' ?? '--'}}</h6>

                </div>
                <div class="content mb-0_5">
                    <h6>Por vencer</h6>
                    <h6 class="mt-0_5 text-primary">{{paymentsHistory.data.details.expiry + ' PEN' ?? '--'}}</h6>

                </div>
                <div class="content mb-0_5">
                    <h6>Consumido %</h6>
                    <h6 class="mt-0_5 text-primary">{{paymentsHistory.data.details.consumed + ' %' ?? '--'}}</h6>

                </div>
                <div class="content mb-0_5">
                    <h6>Vencida</h6>
                    <h6 class="mt-0_5 text-primary">{{paymentsHistory.data.details.debt + ' PEN' ?? '--'}}</h6>

                </div>
            </div>
        </div>
        <div class="info-container-documents mb-0_5 p-2">
            <h4 class="text-primary">Documentos emitidos</h4>
            <div class="info-content info-container-grid mt-1">
                <div class="content mb-0_5">
                    <h6>Letras</h6>
                    <h6 class="mt-0_5 text-primary">{{paymentsHistory.data.documentsIssued.letters + ' PEN' ?? '--'}}</h6>

                </div>
                <div class="content mb-0_5">
                    <h6>Facturas</h6>
                    <h6 class="mt-0_5 text-primary">{{paymentsHistory.data.documentsIssued.bills + ' PEN' ?? '--'}}</h6>

                </div>
                <div class="content mb-0_5">
                    <h6>Notas de crédito</h6>
                    <h6 class="mt-0_5 text-primary">{{paymentsHistory.data.documentsIssued.creditNotes + ' PEN' ?? '--'}}</h6>

                </div>
                <div class="content mb-0_5">
                    <h6>Notas de débito</h6>
                    <h6 class="mt-0_5 text-primary">{{paymentsHistory.data.documentsIssued.debitNotes + ' PEN' ?? '--'}}</h6>

                </div>
                <div class="content mb-0_5">
                </div>
            </div>
        </div>
    </div>

</template>


<script>
import usePaymentsHistory from '../../composables/usePaymentsHistory'
export default {
    setup() {
        const { paymentsHistory } = usePaymentsHistory()
        return {
            paymentsHistory
        }
    }
}
</script>