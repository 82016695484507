import { ref } from 'vue';
import { useRouter } from 'vue-router';

import { useStore } from 'vuex';
import isValidEmail from '@/utils/validations/isValidEmail';

import { encrypt } from '@/utils/account/crypto';
import gaFunction from '@/utils/ga/gaFunction';

const useLogin = () => {
  
  const router = useRouter();
  const store = useStore();

  const isLoading = ref(false);
  const loginModalForm = ref({
    show: false,
    message: '',
  });

  const userForm = ref({
    email: '',
    password: '',
    remind: false,
  });

  const messageForm = ref({
    message: '',
    messageType: '',
  });

  const getCookie = () => {
    var cookies = document.cookie.split(';');
    cookies.forEach(async (cookie) => {
      let cookie_value = cookie.split('=');
      if (cookie_value[0].trim() === 'login-email') {
        let emailFormat = cookie_value[1].split('"');
        userForm.value.email = emailFormat[1];
      }
      if (cookie_value[0].trim() === 'login-pass') {
        userForm.value.password = JSON.parse(
          cookie_value[1]
        ).encryptedData.substr(32);
      }
    });
  };

  getCookie();

  const resetValues = () => {
    messageForm.value.message = '';
    messageForm.value.messageType = '';
    loginModalForm.value.show = false;
    loginModalForm.value.message = '';
  };

  const resetFields = () => {
    userForm.value.email = '';
    userForm.value.password = '';
  };

  const loginUser = async () => {
    isLoading.value = true;
    resetValues();

    if (!isValidEmail(userForm.value.email)) {
      isLoading.value = false;
      messageForm.value.message = 'Ingrese un correo válido';
      messageForm.value.messageType = 'error';
      return;
    }
    if (userForm.value.remind) {
      document.cookie =
        'login-pass' + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      document.cookie =
        'login-email' + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      let v = encrypt(userForm.value.password, userForm.value.email);
      window.document.cookie = 'login-pass' + '=' + JSON.stringify(v);
      window.document.cookie =
        'login-email' + '=' + JSON.stringify(userForm.value.email.toString());
    }
    const { success, message, validatedEmail, rol } = await store.dispatch(
      'auth/signInUser',
      {
        ...userForm.value,
      }
    );

    isLoading.value = false;
    const messageType = success ? 'success' : 'error';

    if (!success && validatedEmail === 1) {
      loginModalForm.value.show = true;
      loginModalForm.value.message = message;
      return;
    }
    messageForm.value.message = message;
    messageForm.value.messageType = messageType;


    const new_user = localStorage.getItem('new-user');
    if (success) {
      isLoading.value = true;
      store.commit("admin/digitalLettersSearch", {
        rucOrName: "",
        isLoading: false,
        data: [],
        select: {},
      });
      store.commit("marketing/statesAccount", {
        rucOrName: "",
        isLoading: false,
        data: {
          data: [],
        },
        select: {},
        fileUrlExcel: null,
        fileUrl: null,
      });
      store.commit("marketing/paymentsHistory", {
        rucOrName: "",
        isLoading: false,
        data: {},
      });
      setTimeout(() => {
        resetFields();
        resetValues();
        if(rol=== '1'){
          gaFunction('event', 'clic', { 'event_category' : 'login', 'event_label' : 'Login admin'});
          router.push({ name : 'homeAdmin' });
          
        }else if(rol === '5'){
          gaFunction('event', 'clic', { 'event_category' : 'login', 'event_label' : 'Login marketing'});
          router.push({ name: 'homeMarketing' });
          
        }else{
          if (new_user === 'true') {
            gaFunction('event', 'clic', { 'event_category' : 'login', 'event_label' : 'Login nuevo usuario'});
            router.push({ path: '/usuario/bienvenido' });
            
          } else {
            gaFunction('event', 'clic', { 'event_category' : 'login', 'event_label' : 'Login cliente'});
            router.push({ path: '/usuario' });
          }
        }
       
      }, 3600);
    }
  };

  return {
    loginUser,
    resetValues,
    isLoading,
    userForm,
    messageForm,
    loginModalForm,
  };
};

export default useLogin;
