<template>
  <DashboardLayout>
    <main class="file-container">
      <div class="file-content">
        <article class="form-content">
          <Header title="Subida de información de archivos" />

          <div class="d-flex justify-center my-2">
            <select
              v-model="select"
              class="select-admin"
              @change=" e => {
                  select = e.target.value; ;
                  clear = true;
              }"
              >
              <option value="cuenta">Estados de cuenta</option>
              <option value="factura">Reporte de facturas</option>
              <option value="historico">Historico de pagos</option>
              <option value="letras">Datos de letras</option>

            </select>
          </div>

       

          <ReportsForm :selected="select" v-model:clear="clear" />

          <div class="text-center mt-2">
            <button class="btn btn-primary" @click="logout">
              Cerrar sesión
            </button>
          </div>
        </article>
      </div>
    </main>
  </DashboardLayout>
  <Footer/>

</template>

<script>
import ReportsForm from './forms/ReportsForm.vue';
import ModalLayout from '@/layouts/modals/ModalLayout';
import ModalContainer from '@/components/ModalContainer';
import useModal from '@/composables/useModal';
import Footer from "@/modules/shared/layouts/Footer";
import ModalTest from '@/modules/auth/views/login/modals/LoginErrorModal';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { ref } from '@vue/reactivity';
import Header from "@/modules/shared/layouts/Header";
import DashboardLayout from '../../../shared/layouts/DashboardLayout';
import useFile from '../../composables/useFile';
export default {
  components: {
    ReportsForm,
    ModalLayout,
    ModalContainer,
    ModalTest,
    Footer,
    DashboardLayout,
    Header
  },

  setup() {
    const { push } = useRouter();
    const store = useStore();
    const clear = ref(false);
    const { select } = useFile();
    const logout = () => {
      store.commit('auth/logout');
      push('/login-admin');
    };

    return {
      ...useModal(),
      select,
      ...store.state,
      push,
      clear,
      logout,
    };
  },
};
</script>
