<template>
    <div class="p-relative">
        <transition name="fade">
            <div class="modal-info" :class="modalInfoBehavior.isOpen ? 'open' : 'close'">
                <template v-if="Object.entries(modalInfoBehavior.data).length > 0">
                    <div class="d-flex justify-end">
                        <i class="fas fa-times d-flex justify-end p-absolute" style="top:15px" @click="() => {
                            modalInfoBehavior.isOpen = false
                            modalInfoBehavior.selected = null
                        }"></i>

                    </div>
                    <div class="modal-info-content-container">
                        <h4 class="mb-1">{{getFormatDate(modalInfoBehavior.data.month + '-' +
                                modalInfoBehavior.data.year, 'behavior')}}</h4>
                        <div class="modal-info-content">
                            <div class="item-behavior-info">
                                <div class="d-flex mb-1 gap-1 align-items-center item-legend text-secondary-2">
                                    <div class="round round-green"></div>
                                    <h6 class="text-low">Riesgo Bajo</h6>
                                </div>
                                <div class="d-flex flex-column">
                                    <p class="mb-0_5"> N° de documentos: {{ modalInfoBehavior.data.low.numberDocuments
                                    }} </p>
                                    <p class="mb-0_5">Monto: {{ modalInfoBehavior.data.low.amount + ' PEN' }}</p>
                                    <p class="mb-0_5">Porcentaje: {{ modalInfoBehavior.data.low.percentage.toFixed(2) + ' %' }}</p>
                                </div>
                            </div>
                            <div class="vertical-line"></div>
                            <div class="item-behavior-info">
                                <div class="d-flex gap-1 mb-1 align-items-center item-legend text-secondary-2">
                                    <div class="round round-yellow"></div>
                                    <h6 class="text-medium">Riesgo Medio</h6>
                                </div>
                                <div class="d-flex flex-column">
                                    <p class="mb-0_5"> N° de documentos: {{
                                            modalInfoBehavior.data.medium.numberDocuments
                                    }}
                                    </p>
                                    <p class="mb-0_5">Monto: {{ modalInfoBehavior.data.medium.amount + ' PEN' }}</p>
                                    <p class="mb-0_5">Porcentage: {{ modalInfoBehavior.data.medium.percentage.toFixed(2) + ' %' }}
                                    </p>
                                </div>
                            </div>
                             <div class="vertical-line"></div>
                            <div class="item-behavior-info">
                                <div class="d-flex gap-1 mb-1 align-items-center item-legend text-secondary-2">
                                    <div class="round round-red"></div>
                                    <h6 class="text-high">Riesgo Alto</h6>
                                </div>
                                <div class="d-flex flex-column">
                                    <p class="mb-0_5"> N° de documentos: {{ modalInfoBehavior.data.high.numberDocuments
                                    }}
                                    </p>
                                    <p class="mb-0_5">Monto: {{ modalInfoBehavior.data.high.amount + ' PEN' }}</p>
                                    <p class="mb-0_5">Porcentage: {{ modalInfoBehavior.data.high.percentage.toFixed(2) + ' %' }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                </template>

            </div>
        </transition>
    </div>
</template>

<script>
import usePaymentsHistory from "../../../composables/usePaymentsHistory";
import getFormatDate from "../../../../../helpers/getFormatDate";
export default {
    setup() {
        const { modalInfoBehavior } = usePaymentsHistory()
        return {
            modalInfoBehavior,
            getFormatDate
        }
    }
}
</script>