<template>
  <div>
    <div id="admin-billing-report">

      <form class="file-form mt-1 text-center" @submit.prevent="">
        <p class="mt-1 text-gray-350 font_helvetica_neue75_bold text-center text-update">
          {{ selected ? user.billing.accountState.lastUpdate
              : user.billing.billingReport.lastUpdate
          }}
        </p>
        <div class="d-flex flex-column h-100 justify-center">
          <div class="file-viewer text-left" v-if="file">
            <h5>Archivo subido:</h5>
            <div class="file-file d-flex">
              <p class="input-file">
                <img src="/images/decorations/excel-logo.png" alt="Excel" />
                {{ file.name }}
              </p>
              <i class="fas fa-times pointer" @click="clearFile"></i>
            </div>
          </div>
          <input type="file" name="file" id="file" class="d-none" @change="setFile_" />
          <LoadingComponent v-if="isLoading" />
          <div v-else class="d-flex flex-column">
            <button class="btn btn-secondary mb-1" @click="download">
              <i class="fas fa-cloud-download-alt"></i>
              Descargar plantilla
            </button>
            <label for="file" class="btn btn-primary" @click="upload">
              <i class="fas fa-cloud-upload-alt"></i>
              {{ file ? "Subir" : "Cargar" }} archivo excel
            </label>
          </div>
        </div>

      </form>
    </div>
    <ModalLayout :isShow="isShow">
      <ModalContainer :close="showOrClose" :typeModal="typeModal" :imageModal="`/images/decorations/${imageModal}`"
        :title="modaltitle" :description="description" :type="type" :withButtons="false" :buttons="[
          { class: 'btn-secondary', route: '/', text: 'Boton 1' },
          { class: 'btn-gold', route: '/', text: 'Boton 2' },
        ]">
      </ModalContainer>

    </ModalLayout>
  </div>
</template>

<script>
import useFile from "@/modules/admin/composables/useFile";
import ModalLayout from "@/layouts/modals/ModalLayout";
import ModalContainer from "@/components/ModalContainer";
import useModal from "@/composables/useModal";
import { reactive } from '@vue/reactivity';
import { useStore } from 'vuex';
import LoadingComponent from '@/components/LoadingComponent';
const baseUrl = process.env.VUE_APP_API_DEV;

export default {
  components: {
    ModalLayout,
    ModalContainer,
    LoadingComponent
  },
  props: {
    selected: {
      type: String,
      required: true
    },
    clear: {
      type: Boolean,
      required: true
    }
  },
  setup() {
    const store = useStore();
    const modalData = reactive({
      modaltitle: '¡Oh no!',
      description: 'el formato del archivo es incorrecto',
      imageModal: 'sorry.png'
    })


    return {
      ...useModal(),
      ...useFile(),
      ...modalData,
      ...store.state
    };
  },
  updated() {
    if (this.clear) {
      this.clearFile();
    }
  },
  methods: {
    setFile_(e) {
      const file = e.target.files[0];
      if (!this.validateFile(file)) {
        this.showOrClose();
        this.clearFile();
        return;
      }
      this.setFile(e);
    },
    async download(){
      const urls = {
        cuenta:"/accountStatus/excel/headings",
        factura:"/billing/excel/headings",
        historico:"/paymentHistory/excel/headings",
        letras:"/letter/excel/headings",
      }
      const token = localStorage.getItem("x-token") || "";
      try {
        const resp = await fetch(baseUrl+urls[this.selected], {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            responseType: 'arraybuffer',
            Authorization: "Bearer  " + token,
          }
        })
        if (resp.ok) {
          const blob = await resp.blob();

          // Create a URL for the blob
          const blobUrl = URL.createObjectURL(blob);

          // Create an anchor element
          const anchor = document.createElement('a');
          anchor.href = blobUrl;
          anchor.download = this.selected+new Date().getTime()+'.xlsx'; // Change the filename accordingly
          anchor.style.display = 'none';

          // Append the anchor to the body and click it
          document.body.appendChild(anchor);
          anchor.click();

          // Clean up
          document.body.removeChild(anchor);
          URL.revokeObjectURL(blobUrl);
        } else {
          console.error('Error fetching the file');
        }
      } catch (error) {
        
      }
    },
    async upload(e) {
      if (this.file) {
        e.preventDefault();
        try {
          const res = await this.uploadFile(this.selected);
          console.log(res);
          if (res.status == 'error') {
            this.modaltitle = '¡Algo salió mal!';
            this.description = res.message ? res.message : 'Por favor inténtalo de nuevo o revisa el archivo';
            this.imageModal = 'sorry.png';
            this.showOrClose();
            this.clearFile();
            this.isLoading = false;
            return
          }
          let minutes = new Date().getMinutes() > 9 ? new Date().getMinutes() : '0' + new Date().getMinutes();
          let last_update_time = new Date().getHours() + ":" + minutes;
          if (this.selected) {
            this.user.billing.accountState.lastUpdate = `Estado de cuenta actualizado hasta el día ${res.last_update} a las ${last_update_time} hrs.`
          } else {
            this.user.billing.billingReport.lastUpdate = `Estado de cuenta actualizado hasta el día ${res.last_update}} a las ${last_update_time} hrs.`
          }
          if (res.duplicate_records > 0) {
            this.modaltitle = res.message;
            this.description = 'La fecha del último documento ingresado es: ' + res.last_update;
            this.imageModal = 'sorry.png';
            this.showOrClose();
            this.clearFile();
            this.isLoading = false;
            return
          }
          this.modaltitle = '¡Enhorabuena!';
          this.description = 'Tu archivo ha sido subido exitosamente.';
          this.imageModal = 'success.png';
          this.showOrClose();
          this.clearFile();
          this.isLoading = false;
        } catch (error) {
          console.log(error);
          this.modaltitle = '¡Algo salió mal!';
          this.description = 'Por favor inténtalo de nuevo';
          this.imageModal = 'sorry.png';
          this.showOrClose();
          this.clearFile();
          this.isLoading = false;
        }
      }
      return;
    },
    clearFile() {
      this.$emit("update:clear", false);
      document.getElementById("file").value = null;
      this.unselectFile();
      this.company = 'company';
    },
  }
};
</script>

