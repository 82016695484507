
const useGauge = () => {
  const calcGauge = () => {
    const gaugeValue = document.querySelector("#gaugenum").textContent;
    const arcs = document.querySelectorAll("#gauge-graphic .arc");
    let elem = 0;

    while (elem < arcs.length) {
      const identifier = "element" + elem;
      const degrees = (gaugeValue / 999) * 180;
      document.styleSheets[0].insertRule(
        `#${identifier}::before {transition: transform 3s cubic-bezier(.3,.2,.45,1.5) 1s; transform: rotate(${degrees}deg)}`,
        document.styleSheets[0].cssRules.length
      );

      arcs[elem].setAttribute("id", identifier);

      elem++;

    }
  };

  return {
    calcGauge
  }

}

export default useGauge;