<template>
    <div class="table-pagination d-flex justify-between mt-1" v-if="statesAccount.data.total > 5">
        <div class="pages">
            <button class="btn next btn-pagination-admin" @click="changePage(statesAccount.data.current_page - 1)" v-if="statesAccount.data.current_page > 1">
                <i class="fas fa-chevron-left"></i>
            </button>
            <template v-for="n in statesAccount.data.last_page " :key="n">
                <button
                    @click="changePage(n)"
                    v-if="n === statesAccount.data.current_page || n === statesAccount.data.current_page + 1"
                    :class="`btn btn-pagination-admin ${n === statesAccount.data.current_page ? 'page-current' : 'next'}`">
                    <span>{{ n }}</span>
                </button>
            </template>
            <span class="suspensives" v-if="statesAccount.data.current_page < statesAccount.data.last_page - 2">...</span>
            <button class="btn next btn-pagination-admin"
                @click="changePage(statesAccount.data.last_page)"
                v-if="statesAccount.data.current_page < statesAccount.data.last_page - 1">
                {{ statesAccount.data.last_page }}
            </button>
        </div>
        <button
        class="btn btn-primary btn-next"
        @click="changePage(statesAccount.data.current_page + 1)"
        v-if="statesAccount.data.current_page < statesAccount.data.last_page"
      >
        <span>Siguiente</span>
        <i class="fas fa-chevron-right"></i>
      </button>
    </div>
</template>


<script>
import useStatesAccount from '../../composables/useStatesAccount'
export default {
    setup() {
        const { statesAccount , changePage } = useStatesAccount()

        return {
            statesAccount,
            changePage
        }
    }
}
</script>