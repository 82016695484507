<template>
    <div class="scroll mb-3 font_helvetica_neue55_roman">
        <div class="account-info-body mt-3 mx-3 mb-3">
            <div class="header_info">
                <h3 class="mt-1 mb-1 font_helvetica_neue75_bold text-primary">
                    Tipo de documento:
                </h3>
                <h3 class="mt-1 mb-2 font_helvetica_neue55_roman">{{ data.letter_type }}</h3>
            </div>

            <div class="request">
                <h3 class="mt-1 mb-1 font_helvetica_neue75_bold text-primary">
                    N° de letra:
                </h3>
                <h3 class="mt-1 mb-2 font_helvetica_neue55_roman">{{ data.letter_number }}</h3>
            </div>

            <div class="document">
                <h3 class="
                mt-1
                mb-1
                font_helvetica_neue75_bold
                text-primary
                d-flex
                justify-between
                align-items-center
            ">
                    Ref. del girador
                </h3>
                <h3 class="mt-1 mb-2">{{ data.ref_giro }}</h3>
            </div>
            <div class="document-date">
                <h3 class="
                mt-1
                mb-1
                font_helvetica_neue75_bold
                text-primary
                d-flex
                justify-between
                align-items-center
            ">
                    Fecha de giro
                </h3>
                <div class="mt-1 mb-2">
                    <h3 class="font_helvetica_neue55_roman">{{(data.date_giro ?  getFormatDate(data.date_giro) : '--') ?? '--' }}</h3>
                </div>
            </div>
            <div class="document-date-expire">
                <h3 class="
                mt-1
                mb-1
                font_helvetica_neue75_bold
                text-primary
                d-flex
                justify-between
                align-items-center
            ">
                    Moneda
                </h3>
                <div class="mt-1 mb-2 d-flex justify-between align-items-center">
                    <h3 class="font_helvetica_neue55_roman">PEN</h3>
                </div>
            </div>

            <div class="reference">
                <h3 class="
                mt-1
                mb-1
                font_helvetica_neue75_bold
                text-primary
                d-flex
                justify-between
                align-items-center
            ">
                    Importe
                </h3>
                <div class="mt-1 mb-2">
                    <h3 class="font_helvetica_neue55_roman">{{data.amount}}</h3>
                </div>
            </div>
            <div class="amount">
                <h3 class="
                mt-1
                mb-1
                font_helvetica_neue75_bold
                text-primary
                d-flex
                justify-between
                align-items-center
            ">
                   Lugar de giro
                </h3>
               <div class="mt-1 mb-2">
                    <h3 class="font_helvetica_neue55_roman">{{data.place_giro}}</h3>
                </div>
            </div>

            <div class="gui">
                <h3 class="
                mt-1
                mb-1
                font_helvetica_neue75_bold
                text-primary
                d-flex
                justify-between
                align-items-center
            ">
                   Fecha de vencimiento
                </h3>
                <div class="mt-1 mb-2">
                    <h3 class="font_helvetica_neue55_roman">{{(data.expiration_date ?  getFormatDate(data.expiration_date) : '--') ?? '--' }}</h3>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import getFormatDate from '../../../../helpers/getFormatDate'
export default {
    props: {
        data: {
            type: Object,
        },
    },
    setup(){
        return {
            getFormatDate
        }
    },
}
</script>