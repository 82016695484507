<template>
    <div class="d-flex justify-center behavior-content-header align-items-center">
      <h2 class="text-primary">Score</h2>
      <div id="gauge-graphic">
        <div class="gauge-font">0</div>
        <!-- Aplicamos la clase dinámica al arco -->
        <div :class="['arc', arcColor]" />
        <div class="gauge-font">999</div>
      </div>
      <h2 :style="{ color: scoreTextColor }" class="text-primary" id="gaugenum">
        {{ scoringScore }}
      </h2>
    </div>
  </template>
  
  <script>
  export default {
    name: "GaugeGraphic",
    props: {
      scoringScore: {
        type: Number,
        required: true,
      },
    },
    computed: {
      // Computed property para determinar el color del arco
      arcColor() {
        if (this.scoringScore <= 333) {
          return "red";
        } else if (this.scoringScore <= 666) {
          return "yellow";
        } else {
          return "green";
        }
      },
      scoreTextColor() {
        if (this.scoringScore <= 333) {
            return "red";
        } else if (this.scoringScore <= 666) {
            return "yellow";
        } else {
            return "green";
        }
        },
    },
  };
  </script>
  